<!--
* @description
* This component is used to display  a title at the top and highlighted styles for article or a list of articles that will be passed like children.
* It is used in the frontpage and paper theme's articles's, featured articles.
  -->

<template>
  <div class="rounded-lg bg-darkerblue text-white pb-2">
    <div class="group p-4">
      <NuxtLink
        v-if="props.link"
        :to="props.link"
        class="flex items-center gap-3"
      >
        <span class="font-sans font-bold text-[15px] leading-6 md:text-[20px]">
          {{ props.title }}</span
        >
        <CommonFontAwesomeIcon
          class="text-white text-sm transition-transform duration-200 ease-in-out transform group-hover:translate-x-2"
          icon="fa-regular fa-chevron-right"
        />
      </NuxtLink>
      <span
        v-else
        class="font-sans font-bold text-[15px] leading-6 md:text-[20px]"
      >
        {{ props.title }}</span
      >
    </div>
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    title: string
    link?:
      | string
      | {
          name: string
          params: Record<string, string | undefined>
        }
  }>(),
  {
    link: undefined,
  }
)
</script>
