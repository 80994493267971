<template>
  <template v-if="!hideComponent && articles && articles.length > 0">
    <NuxtLink
      :to="{
        name: 'sponsored_id',
        params: {
          id: article.urlKey ?? article.id,
        },
      }"
      v-for="article in articles"
      class="flex md:flex-col shadow-teaser bg-[#2C2CA41A] rounded-xl md:overflow-hidden p-3 md:p-0 group"
    >
      <NuxtImg
        :src="getImageUrlFromArticle(article)"
        :width="460"
        :height="260"
        class="rounded md:rounded-none flex w-28 h-16 md:w-auto md:h-auto grow-0 shrink-0 items-center justify-center"
      />
      <div
        class="w-full md:px-6 md:py-6 ml-3 md:ml-0 font-[Georgia] rounded-xl rounded-t-none border border-t-0 border-grey-200"
      >
        <span class="text-blue font-bold text-xs/4 flex mb-1.5"
          >{{ $t('ads.nativeAds1') }} - {{ article.customWriter }}</span
        >
        <h2
          class="text-[13px]/4 md:text-2xl/[30px] font-bold text-[#000000] -tracking-[0.005em] group-hover:underline decoration-1 md:decoration-2"
        >
          {{ article.headline }}
        </h2>
      </div>
    </NuxtLink>
  </template>
</template>
<script setup lang="ts">
import type { ArticleDTO } from '~/typesAuto/apicore/v1'

const props = withDefaults(
  defineProps<{
    article?: ArticleDTO
    compact?: boolean
    top?: number
    offset?: number
  }>(),
  {
    top: 1,
    offset: 0,
  }
)
const indexStore = useIndexStore()
const currentPaperId = computed(() => indexStore.currentPaper?.RecordId ?? 1)
const nuxtApp = useNuxtApp()
const { data } = await useAsyncData(
  `article-sponsored-teaser-top-${props.top}-offset-${props.offset}`,
  async () => {
    if (props.article) return
    const articles = await nuxtApp.$api.articles.getSponsoredArticles({
      paperId: currentPaperId.value,
      $top: props.top + props.offset,
      offset: props.offset,
    })
    return {
      ...articles,
      data: articles.data.slice(0, 1),
    }
  }
)
const hideComponent = computed(() => {
  const someTeaserIsInvalid = articles.value?.some(
    (article) => !article.customWriter
  )
  return !props.article?.customWriter && someTeaserIsInvalid
})
const articles = computed(() => {
  return props.article ? [props.article] : data.value?.data
})
function getImageUrlFromArticle(article: ArticleDTO): string | undefined {
  if (!article.images) {
    return
  }
  return article.images[0]?.url ?? undefined
}
</script>
