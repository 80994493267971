<template>
  <div
    v-if="props.articles?.length > 0"
    class="grid grid-cols-2 md:grid-cols-9 md:gap-x-8"
    :class="{
      'gap-y-2': props.sameSize || props.reverseOrder,
      'gap-y-5': !props.sameSize && !props.reverseOrder,
    }"
  >
    <!-- Articles in one line -->
    <template v-if="props.sameSize">
      <div v-for="(article, index) in props.articles" class="col-span-3">
        <B2CPodcastTeaser
          v-if="article.Type && getArticleType(article.Type) === 'podcast'"
          :article="article"
        />
        <B2CArticleTeaser
          v-else-if="
            !article.FrontpageTheme &&
            !article.FeaturedArticle &&
            !article.SponsoredArticle
          "
          :key="'article' + index"
          :article="article"
          :index="index"
          :lazy="false"
          :preload="props.preloadFirstImage"
          :same-size="props.sameSize"
        />
      </div>
    </template>
    <template v-else>
      <!-- Slot for Sponsored article -->
      <div class="col-span-full md:hidden">
        <slot></slot>
      </div>
      <!-- First Article -->
      <div
        class="col-span-full md:col-span-6 md:row-span-2"
        :class="
          props.reverseOrder
            ? 'md:col-start-4 md:col-end-10'
            : 'md:col-start-1 md:col-end-7'
        "
      >
        <B2CPodcastTeaser
          v-if="
            firstArticle.Type && getArticleType(firstArticle.Type) === 'podcast'
          "
          :article="firstArticle"
        />
        <B2CArticleTeaser
          v-else-if="
            !firstArticle.FrontpageTheme &&
            !firstArticle.FeaturedArticle &&
            !firstArticle.SponsoredArticle
          "
          :key="'article' + 'first'"
          :article="firstArticle"
          :index="0"
          :lazy="false"
          :preload="props.preloadFirstImage"
          :same-size="props.sameSize"
          :mobile-small="props.firstArticleMobileSmall"
        />

        <B2CArticleTeaserThemeFrame
          v-else-if="
            firstArticle.FrontpageTheme && firstArticle.FrontpageTheme.Articles
          "
          :key="'article FrontpageTheme' + 0"
          title="Thes is theme"
          :link="{
            name: indexStore.currentPaperSlug ? 'paper_subject' : 'subject',
            params: {
              subject: firstArticle.FrontpageTheme.ThemeId,
              paper: indexStore.currentPaperSlug,
            },
          }"
        >
          <B2CArticleTeaser
            v-for="(teaser, index) in firstArticle.FrontpageTheme.Articles"
            :article="teaser"
            :index="index"
            isFramed
          />
        </B2CArticleTeaserThemeFrame>
      </div>
      <!-- Other Articles -->
      <div
        class="col-span-full md:col-span-3 md:row-start-1"
        :class="
          reverseOrder
            ? 'md:col-start-1 md:col-end-4'
            : 'md:col-start-7 md:col-end-10'
        "
      >
        <div class="grid grid-cols-1 gap-2 md:gap-y-8">
          <div v-for="(article, index) in props.articles.slice(1)">
            <B2CPodcastTeaser
              v-if="
                firstArticle.Type &&
                getArticleType(firstArticle.Type) === 'podcast'
              "
              :article="firstArticle"
            />
            <B2CArticleTeaser
              v-else-if="
                !article.FrontpageTheme &&
                !article.FeaturedArticle &&
                !article.SponsoredArticle
              "
              :key="'article' + index"
              :article="article"
              :index="index + 1"
              :lazy="false"
              :preload="props.preloadFirstImage"
              :same-size="props.sameSize"
            />
          </div>
          <div
            class="hidden"
            :class="{
              'md:block': $slots.default,
            }"
          >
            <slot></slot>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { ContentArticle } from '~/typesManual/content_api/article'
const indexStore = useIndexStore()

export type ArticleType =
  | 'instream'
  | 'news'
  | 'writer'
  | 'person'
  | 'column'
  | 'subject'
  | 'articletype'
  | 'subject'

const props = withDefaults(
  defineProps<{
    articles: ContentArticle[]
    count?: number
    offset?: number
    lazy?: boolean
    preloadFirstImage?: boolean
    reverseOrder?: boolean
    sameSize?: boolean
    firstArticleMobileSmall?: boolean
  }>(),
  {
    offset: 0,
    lazy: true,
    preloadFirstImage: false,
    reverseOrder: false,
    sameSize: false,
    firstArticleMobileSmall: false,
  }
)

const firstArticle = computed(() => {
  return props.articles[0]
})
</script>
