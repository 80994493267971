<template>
  <div class="mt-5 md:mt-8">
    <div
      class="container-narrow no-print flex flex-col gap-y-4 md:gap-y-8 mb-40"
    >
      <B2CListArticles
        :articles="articleSections ? articleSections[0]?.slice(0, 3) : []"
        :lazy="false"
        :preload-first-image="true"
      />

      <ListJobs b2c :count="8" :random="true" />

      <div v-if="pending" class="flex justify-center">
        <CommonLoading />
      </div>
      <div v-else class="flex flex-col gap-y-2 md:gap-y-8">
        <B2CListArticles
          :articles="articleSections ? articleSections[0]?.slice(3, 5) : []"
          :reverse-order="true"
          :first-article-mobile-small="true"
          :preload-first-image="true"
        >
          <B2CArticleSponsoredTeaser
        /></B2CListArticles>

        <B2CListArticles
          :articles="articleSections ? articleSections[0]?.slice(5, 8) : []"
          :same-size="true"
        />
      </div>

      <AdsConceptAd
        :id="`${config.public.site.ads?.placementPrefix}-rec1`"
        class="md-4 md:mb-10"
      />

      <transition-group name="fade" tag="div" class="space-y-4 mt-4">
        <div
          v-if="articleSections && articleSections.length > 1"
          v-for="(articleSection, index) in moreArticleSections"
          :key="index"
        >
          <B2CListArticles
            :articles="articleSection?.slice(0, 3)"
            class="mb-2 md:mb-6"
          />

          <div class="flex flex-col gap-y-2 md:gap-y-8">
            <B2CListArticles
              :articles="articleSection?.slice(3, 5)"
              :reverse-order="true"
              :first-article-mobile-small="true"
            >
              <B2CArticleSponsoredTeaser
            /></B2CListArticles>

            <B2CListArticles
              :articles="articleSection?.slice(5, 8)"
              :same-size="true"
              class="mb-2 md:mb-6"
            />
          </div>
        </div>
      </transition-group>
      <div class="flex justify-center" v-if="displayLoadMoreButton">
        <CommonButton @click="onLoadMoreClick">
          {{ $t('ShowMore') }}
        </CommonButton>
      </div>
      <NewsletterSignup class="mt-4" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ContentArticle } from '~/typesManual/content_api/article'

const config = useRuntimeConfig()
const indexStore = useIndexStore()
const currentOffset = ref(0)
const currentCount = ref(16)

const {
  data: articles,
  loadMore,
  pending,
} = await useArticles({
  paper: 1,
  frontend: true, // Flags to use Saga API
  count: currentCount.value,
  offset: currentOffset.value,
})

const articleSections = computed(() => {
  if (!articles.value?.length) {
    return []
  }

  const chunkSize = 8

  return articles.value.reduce<ContentArticle[][]>(
    (sections, article, index) => {
      const chunkIndex = Math.floor(index / chunkSize)

      if (!sections[chunkIndex]) {
        sections[chunkIndex] = [] // Initialize a new chunk
      }

      sections[chunkIndex].push(article)

      return sections
    },
    []
  )
})

const moreArticleSections = computed(() => {
  return articleSections.value?.filter((_, index) => index > 0)
})

const displayLoadMoreButton = ref<boolean>(true)

const onLoadMoreClick = async () => {
  currentCount.value = 8
  currentOffset.value += 16
  await loadMore(currentOffset.value)
}

useSeoMeta({
  title: indexStore.currentPaperSlug
    ? config.public.site.meta.title.postfix.short +
      indexStore.currentPaper?.Name
    : config.public.site.meta.title.postfix.long,
})
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  @apply transition-opacity duration-500;
}
.fade-enter-from,
.fade-leave-to {
  @apply opacity-0;
}
.fade-enter-to,
.fade-leave-from {
  @apply opacity-100;
}
</style>
